import { createNamespacedHelpers } from 'vuex'
import configTypes from '~/store/storeConfig/types'
import staticTypes from '~/store/staticBlock/types'
import storeCode from '~/mixins/storeCode'

const {
  mapGetters: mapGettersConfig,
} = createNamespacedHelpers('storeConfig')

const {
  mapGetters: mapGettersStatic,
} = createNamespacedHelpers('staticBlock')

export default {
  mixins: [
    storeCode,
  ],
  props: {
    categories: {
      type: Array,
      required: true,
      default: () => [],
    },
    prefix: {
      type: String,
      default: 'c',
    },
    currentCategoryUrlKey: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGettersConfig({
      categoriesInMenu: configTypes.GET_CATEGORIES_IN_MENU,
    }),

    ...mapGettersStatic({
      getBlocks: staticTypes.GET_BLOCKS,
    }),

    staticMenuArray () {
      return this.getBlocks('static-menu')['static-menu']?.contentJson || []
    },

    isAdditionalMenuFromStaticBLock () {
      return this.staticMenuArray.length > 0
    },

    categoriesInMenu () {
      const categoryInMenuFilter = (acc, item) => {
        if (item.includeInMenu) {
          if (item.children.length > 0) {
            const children = item.children?.reduce(categoryInMenuFilter, [])

            acc.push({
              ...item,
              children,
            })
          } else {
            acc.push(item)
          }
        }
        return acc
      }

      let menu = this.categories?.reduce(categoryInMenuFilter, [])

      if (this.isAdditionalMenuFromStaticBLock) {
        menu = this.addStaticBlockCategory(menu, this.staticMenuArray)
      }

      return menu
    },
  },
  methods: {
    addStaticBlockCategory (menu, staticMenu) {
      const localMenu = menu

      staticMenu.forEach((item) => {
        const { main, positionLevel, children } = item

        if (main) {
          localMenu.splice(positionLevel, 0, item)
        } else if (children?.length > 0) {
          children.forEach((el) => {
            if (el.children?.length > 0) {
              localMenu[positionLevel].children.splice(el?.positionLevel, 0, el)

              this.addStaticBlockCategory(localMenu, el?.children)
            } else if (el?.singleSubLink) {
              localMenu[positionLevel].children.splice(el?.positionLevel, 0, el)
            }
          })
        }
      })

      return localMenu
    },

    createPath (item, prefix, currentCategoryUrlKey) {
      if (this.isMedistoreStore && currentCategoryUrlKey) {
        return `/${prefix}/${currentCategoryUrlKey}/${item.urlKey}`
      }

      if (this.isAdditionalMenuFromStaticBLock && item?.static) {
        return `${item.urlKey}`
      }

      const pathDictionary = this.findAllByKey(this.categories, 'path').map(item => ({ [`${(item.path || '').split('/').join('')}`]: item.urlKey }))
      let letterPathCollector = ''
      let digitalPathTemp = ''

      const digitalPath = [...(item.path).split('/')]

      digitalPath.forEach((element) => {
        digitalPathTemp += element

        const letterPath = pathDictionary.find((element) => {
          return (Object.keys(element)[0] === digitalPathTemp) && digitalPathTemp !== '0'
        })

        if (letterPath) {
          letterPathCollector += `/${Object.values(letterPath)[0]}`
        }
      })

      return `/${prefix}${letterPathCollector}`
    },

    findAllByKey (obj, keyToFind) {
      return obj
        ? Object.entries(obj)
          .reduce((acc, [key, value]) => (key === keyToFind)
            ? acc.concat(obj)
            : (typeof value === 'object' && value !== null && key !== 'languageVersions')
                ? acc.concat(this.findAllByKey(value, keyToFind))
                : acc
          , [])
        : []
    },
  },
}


import { debounce } from 'debounce'
import search from '~/mixins/search'

export default {
  name: 'HeaderSearchResults',
  mixins: [
    search,
  ],

  props: {
    query: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      isBackspace: false,
    }
  },

  computed: {
    products () {
      return [...this.$search.products()].splice(0, 5)
    },

    productsGroup () {
      return this.products.reduce((acc, product) => {
        acc[product.mappedProductType] = acc[product.mappedProductType] || []
        acc[product.mappedProductType].push(product)
        return acc
      }, {})
    },

    isSearchPage () {
      return /\/search\/q/.test(this.$route.path)
    },
  },

  watch: {
    query (newItem) {
      if (!this.isBackspace && newItem.length >= 3) {
        this.pushGtm(newItem)
      }
    },
  },

  mounted () {
    window.addEventListener('keydown', this.onKeydown)
  },

  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeydown)
  },

  methods: {
    clickedOnProduct () {
      this.$emit('hide-results', 'clickedOnProduct')
    },

    boldText (text) {
      const regExp = new RegExp(this.query, 'gi')

      return text.replace(regExp, '<strong>$&</strong>')
    },

    // @todo remove after BE fix
    removeHost (url) {
      return `${url.substring(url.indexOf(/p/))}`
    },

    pushGtm: debounce(function (item) {
      this.$gtm.push({
        event: this.products.length > 0 ? 'search_main' : 'search_test',
        search_term: item,
      })
    }, 300),

    onKeydown (event) {
      this.isBackspace = event.key === 'Backspace'
    },
  },
}

export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const CheckoutBeneficiary = () => import('../../node_modules/@strix/checkout/components/CheckoutBeneficiary.vue' /* webpackChunkName: "components/checkout-beneficiary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutBlik = () => import('../../node_modules/@strix/checkout/components/CheckoutBlik.vue' /* webpackChunkName: "components/checkout-blik" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeader = () => import('../../node_modules/@strix/checkout/components/CheckoutHeader.vue' /* webpackChunkName: "components/checkout-header" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeaderBackLink = () => import('../../node_modules/@strix/checkout/components/CheckoutHeaderBackLink.vue' /* webpackChunkName: "components/checkout-header-back-link" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeaderCart = () => import('../../node_modules/@strix/checkout/components/CheckoutHeaderCart.vue' /* webpackChunkName: "components/checkout-header-cart" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeaderReservationTimer = () => import('../../node_modules/@strix/checkout/components/CheckoutHeaderReservationTimer.vue' /* webpackChunkName: "components/checkout-header-reservation-timer" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeaderStepperTopBelt = () => import('../../node_modules/@strix/checkout/components/CheckoutHeaderStepperTopBelt.vue' /* webpackChunkName: "components/checkout-header-stepper-top-belt" */).then(c => wrapFunctional(c.default || c))
export const CheckoutInformation = () => import('../../node_modules/@strix/checkout/components/CheckoutInformation.vue' /* webpackChunkName: "components/checkout-information" */).then(c => wrapFunctional(c.default || c))
export const CheckoutInvoiceInformation = () => import('../../node_modules/@strix/checkout/components/CheckoutInvoiceInformation.vue' /* webpackChunkName: "components/checkout-invoice-information" */).then(c => wrapFunctional(c.default || c))
export const CheckoutMaterialFeePrice = () => import('../../node_modules/@strix/checkout/components/CheckoutMaterialFeePrice.vue' /* webpackChunkName: "components/checkout-material-fee-price" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPayment = () => import('../../node_modules/@strix/checkout/components/CheckoutPayment.vue' /* webpackChunkName: "components/checkout-payment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPurchaserForm = () => import('../../node_modules/@strix/checkout/components/CheckoutPurchaserForm.vue' /* webpackChunkName: "components/checkout-purchaser-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPurchaserFormRo = () => import('../../node_modules/@strix/checkout/components/CheckoutPurchaserFormRo.vue' /* webpackChunkName: "components/checkout-purchaser-form-ro" */).then(c => wrapFunctional(c.default || c))
export const CheckoutRegisterBox = () => import('../../node_modules/@strix/checkout/components/CheckoutRegisterBox.vue' /* webpackChunkName: "components/checkout-register-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutReservationTimeoutToast = () => import('../../node_modules/@strix/checkout/components/CheckoutReservationTimeoutToast.vue' /* webpackChunkName: "components/checkout-reservation-timeout-toast" */).then(c => wrapFunctional(c.default || c))
export const CheckoutShippingPrice = () => import('../../node_modules/@strix/checkout/components/CheckoutShippingPrice.vue' /* webpackChunkName: "components/checkout-shipping-price" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSummary = () => import('../../node_modules/@strix/checkout/components/CheckoutSummary.vue' /* webpackChunkName: "components/checkout-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSummaryGiftCard = () => import('../../node_modules/@strix/checkout/components/CheckoutSummaryGiftCard.vue' /* webpackChunkName: "components/checkout-summary-gift-card" */).then(c => wrapFunctional(c.default || c))
export const CheckoutTransactionTerms = () => import('../../node_modules/@strix/checkout/components/CheckoutTransactionTerms.vue' /* webpackChunkName: "components/checkout-transaction-terms" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItem = () => import('../../node_modules/@strix/checkout/components/item/CheckoutItem.vue' /* webpackChunkName: "components/checkout-item" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemBar = () => import('../../node_modules/@strix/checkout/components/item/CheckoutItemBar.vue' /* webpackChunkName: "components/checkout-item-bar" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemCareDate = () => import('../../node_modules/@strix/checkout/components/item/CheckoutItemCareDate.vue' /* webpackChunkName: "components/checkout-item-care-date" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemDuration = () => import('../../node_modules/@strix/checkout/components/item/CheckoutItemDuration.vue' /* webpackChunkName: "components/checkout-item-duration" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemGrouped = () => import('../../node_modules/@strix/checkout/components/item/CheckoutItemGrouped.vue' /* webpackChunkName: "components/checkout-item-grouped" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemLensesParameters = () => import('../../node_modules/@strix/checkout/components/item/CheckoutItemLensesParameters.vue' /* webpackChunkName: "components/checkout-item-lenses-parameters" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemQuantity = () => import('../../node_modules/@strix/checkout/components/item/CheckoutItemQuantity.vue' /* webpackChunkName: "components/checkout-item-quantity" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemRemove = () => import('../../node_modules/@strix/checkout/components/item/CheckoutItemRemove.vue' /* webpackChunkName: "components/checkout-item-remove" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemRemoveAll = () => import('../../node_modules/@strix/checkout/components/item/CheckoutItemRemoveAll.vue' /* webpackChunkName: "components/checkout-item-remove-all" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemRestrictions = () => import('../../node_modules/@strix/checkout/components/item/CheckoutItemRestrictions.vue' /* webpackChunkName: "components/checkout-item-restrictions" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItems = () => import('../../node_modules/@strix/checkout/components/item/CheckoutItems.vue' /* webpackChunkName: "components/checkout-items" */).then(c => wrapFunctional(c.default || c))
export const CheckoutModalBeneficiary = () => import('../../node_modules/@strix/checkout/components/modal/CheckoutModalBeneficiary.vue' /* webpackChunkName: "components/checkout-modal-beneficiary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutModalBeneficiaryRo = () => import('../../node_modules/@strix/checkout/components/modal/CheckoutModalBeneficiaryRo.vue' /* webpackChunkName: "components/checkout-modal-beneficiary-ro" */).then(c => wrapFunctional(c.default || c))
export const CheckoutModalMedicalPackageOptions = () => import('../../node_modules/@strix/checkout/components/modal/CheckoutModalMedicalPackageOptions.vue' /* webpackChunkName: "components/checkout-modal-medical-package-options" */).then(c => wrapFunctional(c.default || c))
export const CheckoutModalPayment = () => import('../../node_modules/@strix/checkout/components/modal/CheckoutModalPayment.vue' /* webpackChunkName: "components/checkout-modal-payment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutModalShippingAddress = () => import('../../node_modules/@strix/checkout/components/modal/CheckoutModalShippingAddress.vue' /* webpackChunkName: "components/checkout-modal-shipping-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutModalShippingInpost = () => import('../../node_modules/@strix/checkout/components/modal/CheckoutModalShippingInpost.vue' /* webpackChunkName: "components/checkout-modal-shipping-inpost" */).then(c => wrapFunctional(c.default || c))
export const CheckoutModalUpdateCart = () => import('../../node_modules/@strix/checkout/components/modal/CheckoutModalUpdateCart.vue' /* webpackChunkName: "components/checkout-modal-update-cart" */).then(c => wrapFunctional(c.default || c))
export const CheckoutModalUpdateLensesParameters = () => import('../../node_modules/@strix/checkout/components/modal/CheckoutModalUpdateLensesParameters.vue' /* webpackChunkName: "components/checkout-modal-update-lenses-parameters" */).then(c => wrapFunctional(c.default || c))
export const CheckoutProductDetails = () => import('../../node_modules/@strix/checkout/components/product/CheckoutProductDetails.vue' /* webpackChunkName: "components/checkout-product-details" */).then(c => wrapFunctional(c.default || c))
export const CheckoutProductsAgreements = () => import('../../node_modules/@strix/checkout/components/product/CheckoutProductsAgreements.vue' /* webpackChunkName: "components/checkout-products-agreements" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSummaryLogged = () => import('../../node_modules/@strix/checkout/components/summary/CheckoutSummaryLogged.vue' /* webpackChunkName: "components/checkout-summary-logged" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSummaryLogout = () => import('../../node_modules/@strix/checkout/components/summary/CheckoutSummaryLogout.vue' /* webpackChunkName: "components/checkout-summary-logout" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSummaryMaterialFee = () => import('../../node_modules/@strix/checkout/components/summary/CheckoutSummaryMaterialFee.vue' /* webpackChunkName: "components/checkout-summary-material-fee" */).then(c => wrapFunctional(c.default || c))
export const CheckoutWidgetAddToCart = () => import('../../node_modules/@strix/checkout/components/widget/CheckoutWidgetAddToCart.vue' /* webpackChunkName: "components/checkout-widget-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const CheckoutWidgetAddToCartModal = () => import('../../node_modules/@strix/checkout/components/widget/CheckoutWidgetAddToCartModal.vue' /* webpackChunkName: "components/checkout-widget-add-to-cart-modal" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemBeneficiaryList = () => import('../../node_modules/@strix/checkout/components/item/beneficiary/CheckoutItemBeneficiaryList.vue' /* webpackChunkName: "components/checkout-item-beneficiary-list" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemDetail = () => import('../../node_modules/@strix/checkout/components/item/detail/CheckoutItemDetail.vue' /* webpackChunkName: "components/checkout-item-detail" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemDetailAddress = () => import('../../node_modules/@strix/checkout/components/item/detail/CheckoutItemDetailAddress.vue' /* webpackChunkName: "components/checkout-item-detail-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemGroupedChangeOption = () => import('../../node_modules/@strix/checkout/components/item/grouped/CheckoutItemGroupedChangeOption.vue' /* webpackChunkName: "components/checkout-item-grouped-change-option" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemShippingAddress = () => import('../../node_modules/@strix/checkout/components/item/shipping/CheckoutItemShippingAddress.vue' /* webpackChunkName: "components/checkout-item-shipping-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemShippingChangeAddress = () => import('../../node_modules/@strix/checkout/components/item/shipping/CheckoutItemShippingChangeAddress.vue' /* webpackChunkName: "components/checkout-item-shipping-change-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemShippingChangeInpost = () => import('../../node_modules/@strix/checkout/components/item/shipping/CheckoutItemShippingChangeInpost.vue' /* webpackChunkName: "components/checkout-item-shipping-change-inpost" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemShippingCourier = () => import('../../node_modules/@strix/checkout/components/item/shipping/CheckoutItemShippingCourier.vue' /* webpackChunkName: "components/checkout-item-shipping-courier" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemShippingInpost = () => import('../../node_modules/@strix/checkout/components/item/shipping/CheckoutItemShippingInpost.vue' /* webpackChunkName: "components/checkout-item-shipping-inpost" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemShippingReadOnly = () => import('../../node_modules/@strix/checkout/components/item/shipping/CheckoutItemShippingReadOnly.vue' /* webpackChunkName: "components/checkout-item-shipping-read-only" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemTypesAppointment = () => import('../../node_modules/@strix/checkout/components/item/types/CheckoutItemTypesAppointment.vue' /* webpackChunkName: "components/checkout-item-types-appointment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemTypesLenses = () => import('../../node_modules/@strix/checkout/components/item/types/CheckoutItemTypesLenses.vue' /* webpackChunkName: "components/checkout-item-types-lenses" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemTypesMaterialFee = () => import('../../node_modules/@strix/checkout/components/item/types/CheckoutItemTypesMaterialFee.vue' /* webpackChunkName: "components/checkout-item-types-material-fee" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemTypesMedicalPackage = () => import('../../node_modules/@strix/checkout/components/item/types/CheckoutItemTypesMedicalPackage.vue' /* webpackChunkName: "components/checkout-item-types-medical-package" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemTypesPrepaidCard = () => import('../../node_modules/@strix/checkout/components/item/types/CheckoutItemTypesPrepaidCard.vue' /* webpackChunkName: "components/checkout-item-types-prepaid-card" */).then(c => wrapFunctional(c.default || c))
export const CheckoutItemTypesSimple = () => import('../../node_modules/@strix/checkout/components/item/types/CheckoutItemTypesSimple.vue' /* webpackChunkName: "components/checkout-item-types-simple" */).then(c => wrapFunctional(c.default || c))
export const SearchBarInfo = () => import('../../node_modules/@strix/search/components/search/SearchBarInfo.vue' /* webpackChunkName: "components/search-bar-info" */).then(c => wrapFunctional(c.default || c))
export const SearchMobile = () => import('../../node_modules/@strix/search/components/search/SearchMobile.vue' /* webpackChunkName: "components/search-mobile" */).then(c => wrapFunctional(c.default || c))
export const SearchNoResult = () => import('../../node_modules/@strix/search/components/search/SearchNoResult.vue' /* webpackChunkName: "components/search-no-result" */).then(c => wrapFunctional(c.default || c))
export const SearchResult = () => import('../../node_modules/@strix/search/components/search/SearchResult.vue' /* webpackChunkName: "components/search-result" */).then(c => wrapFunctional(c.default || c))
export const SearchFilter = () => import('../../node_modules/@strix/search/components/search/filter/SearchFilter.vue' /* webpackChunkName: "components/search-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchSort = () => import('../../node_modules/@strix/search/components/search/sort/SearchSort.vue' /* webpackChunkName: "components/search-sort" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterModalMobile = () => import('../../node_modules/@strix/search/components/search/filter/modal/SearchFilterModalMobile.vue' /* webpackChunkName: "components/search-filter-modal-mobile" */).then(c => wrapFunctional(c.default || c))
export const SearchSortModalMobile = () => import('../../node_modules/@strix/search/components/search/sort/modal/SearchSortModalMobile.vue' /* webpackChunkName: "components/search-sort-modal-mobile" */).then(c => wrapFunctional(c.default || c))
export const TheConpeek = () => import('../../components/TheConpeek.vue' /* webpackChunkName: "components/the-conpeek" */).then(c => wrapFunctional(c.default || c))
export const TheFaq = () => import('../../components/TheFaq.vue' /* webpackChunkName: "components/the-faq" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheFooterV2 = () => import('../../components/TheFooterV2.vue' /* webpackChunkName: "components/the-footer-v2" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const TheHeaderV2 = () => import('../../components/TheHeaderV2.vue' /* webpackChunkName: "components/the-header-v2" */).then(c => wrapFunctional(c.default || c))
export const TheMegaMenu = () => import('../../components/TheMegaMenu.vue' /* webpackChunkName: "components/the-mega-menu" */).then(c => wrapFunctional(c.default || c))
export const ThePages = () => import('../../components/ThePages.vue' /* webpackChunkName: "components/the-pages" */).then(c => wrapFunctional(c.default || c))
export const ThePreview = () => import('../../components/ThePreview.vue' /* webpackChunkName: "components/the-preview" */).then(c => wrapFunctional(c.default || c))
export const HeaderAccount = () => import('../../components/Header/HeaderAccount.vue' /* webpackChunkName: "components/header-account" */).then(c => wrapFunctional(c.default || c))
export const HeaderAccountLogout = () => import('../../components/Header/HeaderAccountLogout.vue' /* webpackChunkName: "components/header-account-logout" */).then(c => wrapFunctional(c.default || c))
export const HeaderButton = () => import('../../components/Header/HeaderButton.vue' /* webpackChunkName: "components/header-button" */).then(c => wrapFunctional(c.default || c))
export const HeaderCart = () => import('../../components/Header/HeaderCart.vue' /* webpackChunkName: "components/header-cart" */).then(c => wrapFunctional(c.default || c))
export const HeaderLocaleSwitcher = () => import('../../components/Header/HeaderLocaleSwitcher.vue' /* webpackChunkName: "components/header-locale-switcher" */).then(c => wrapFunctional(c.default || c))
export const HeaderLogo = () => import('../../components/Header/HeaderLogo.vue' /* webpackChunkName: "components/header-logo" */).then(c => wrapFunctional(c.default || c))
export const HeaderMediclubSurveyBanner = () => import('../../components/Header/HeaderMediclubSurveyBanner.vue' /* webpackChunkName: "components/header-mediclub-survey-banner" */).then(c => wrapFunctional(c.default || c))
export const HeaderInfoBar = () => import('../../components/HeaderV2/HeaderInfoBar.vue' /* webpackChunkName: "components/header-info-bar" */).then(c => wrapFunctional(c.default || c))
export const MapsFilters = () => import('../../components/Maps/MapsFilters.vue' /* webpackChunkName: "components/maps-filters" */).then(c => wrapFunctional(c.default || c))
export const MapsGoogle = () => import('../../components/Maps/MapsGoogle.vue' /* webpackChunkName: "components/maps-google" */).then(c => wrapFunctional(c.default || c))
export const MediButton = () => import('../../components/Medi/MediButton.vue' /* webpackChunkName: "components/medi-button" */).then(c => wrapFunctional(c.default || c))
export const MediCarousel = () => import('../../components/Medi/MediCarousel.vue' /* webpackChunkName: "components/medi-carousel" */).then(c => wrapFunctional(c.default || c))
export const MediContainer = () => import('../../components/Medi/MediContainer.vue' /* webpackChunkName: "components/medi-container" */).then(c => wrapFunctional(c.default || c))
export const MediExpandablePanel = () => import('../../components/Medi/MediExpandablePanel.vue' /* webpackChunkName: "components/medi-expandable-panel" */).then(c => wrapFunctional(c.default || c))
export const MediPagination = () => import('../../components/Medi/MediPagination.vue' /* webpackChunkName: "components/medi-pagination" */).then(c => wrapFunctional(c.default || c))
export const MediRadioSwitcher = () => import('../../components/Medi/MediRadioSwitcher.vue' /* webpackChunkName: "components/medi-radio-switcher" */).then(c => wrapFunctional(c.default || c))
export const MediSearchInput = () => import('../../components/Medi/MediSearchInput.vue' /* webpackChunkName: "components/medi-search-input" */).then(c => wrapFunctional(c.default || c))
export const MediShareButtons = () => import('../../components/Medi/MediShareButtons.vue' /* webpackChunkName: "components/medi-share-buttons" */).then(c => wrapFunctional(c.default || c))
export const MediClubLogo = () => import('../../components/MediClub/MediClubLogo.vue' /* webpackChunkName: "components/medi-club-logo" */).then(c => wrapFunctional(c.default || c))
export const MediClubLogoInline = () => import('../../components/MediClub/MediClubLogoInline.vue' /* webpackChunkName: "components/medi-club-logo-inline" */).then(c => wrapFunctional(c.default || c))
export const MediClubLogoSponsored = () => import('../../components/MediClub/MediClubLogoSponsored.vue' /* webpackChunkName: "components/medi-club-logo-sponsored" */).then(c => wrapFunctional(c.default || c))
export const MediClubRegisterHorizontalBlock = () => import('../../components/MediClub/MediClubRegisterHorizontalBlock.vue' /* webpackChunkName: "components/medi-club-register-horizontal-block" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuDesktop = () => import('../../components/MegaMenu/MegaMenuDesktop.vue' /* webpackChunkName: "components/mega-menu-desktop" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuHamburger = () => import('../../components/MegaMenu/MegaMenuHamburger.vue' /* webpackChunkName: "components/mega-menu-hamburger" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuMobile = () => import('../../components/MegaMenu/MegaMenuMobile.vue' /* webpackChunkName: "components/mega-menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const PaymentMedi = () => import('../../components/Payment/PaymentMedi.vue' /* webpackChunkName: "components/payment-medi" */).then(c => wrapFunctional(c.default || c))
export const PaymentOrderStatus = () => import('../../components/Payment/PaymentOrderStatus.vue' /* webpackChunkName: "components/payment-order-status" */).then(c => wrapFunctional(c.default || c))
export const Price = () => import('../../components/Price/Price.vue' /* webpackChunkName: "components/price" */).then(c => wrapFunctional(c.default || c))
export const PriceField = () => import('../../components/Price/PriceField.vue' /* webpackChunkName: "components/price-field" */).then(c => wrapFunctional(c.default || c))
export const PriceLowest = () => import('../../components/Price/PriceLowest.vue' /* webpackChunkName: "components/price-lowest" */).then(c => wrapFunctional(c.default || c))
export const PriceLowestAZ = () => import('../../components/Price/PriceLowestAZ.vue' /* webpackChunkName: "components/price-lowest-a-z" */).then(c => wrapFunctional(c.default || c))
export const ArticleHorizontalSmallTileList = () => import('../../components/article/ArticleHorizontalSmallTileList.vue' /* webpackChunkName: "components/article-horizontal-small-tile-list" */).then(c => wrapFunctional(c.default || c))
export const ArticleHorizontalTile = () => import('../../components/article/ArticleHorizontalTile.vue' /* webpackChunkName: "components/article-horizontal-tile" */).then(c => wrapFunctional(c.default || c))
export const ArticleList = () => import('../../components/article/ArticleList.vue' /* webpackChunkName: "components/article-list" */).then(c => wrapFunctional(c.default || c))
export const ArticleTag = () => import('../../components/article/ArticleTag.vue' /* webpackChunkName: "components/article-tag" */).then(c => wrapFunctional(c.default || c))
export const ArticleTile = () => import('../../components/article/ArticleTile.vue' /* webpackChunkName: "components/article-tile" */).then(c => wrapFunctional(c.default || c))
export const ArticlesByTag = () => import('../../components/articles/ArticlesByTag.vue' /* webpackChunkName: "components/articles-by-tag" */).then(c => wrapFunctional(c.default || c))
export const ArticlesFeatured = () => import('../../components/articles/ArticlesFeatured.vue' /* webpackChunkName: "components/articles-featured" */).then(c => wrapFunctional(c.default || c))
export const BaseAuthContentBlock = () => import('../../components/base/BaseAuthContentBlock.vue' /* webpackChunkName: "components/base-auth-content-block" */).then(c => wrapFunctional(c.default || c))
export const BaseBadge = () => import('../../components/base/BaseBadge.vue' /* webpackChunkName: "components/base-badge" */).then(c => wrapFunctional(c.default || c))
export const BaseBreadcrumbs = () => import('../../components/base/BaseBreadcrumbs.vue' /* webpackChunkName: "components/base-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/base/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseChips = () => import('../../components/base/BaseChips.vue' /* webpackChunkName: "components/base-chips" */).then(c => wrapFunctional(c.default || c))
export const BaseContainer = () => import('../../components/base/BaseContainer.vue' /* webpackChunkName: "components/base-container" */).then(c => wrapFunctional(c.default || c))
export const BaseDialog = () => import('../../components/base/BaseDialog.vue' /* webpackChunkName: "components/base-dialog" */).then(c => wrapFunctional(c.default || c))
export const BaseDivider = () => import('../../components/base/BaseDivider.vue' /* webpackChunkName: "components/base-divider" */).then(c => wrapFunctional(c.default || c))
export const BaseDocumentLink = () => import('../../components/base/BaseDocumentLink.vue' /* webpackChunkName: "components/base-document-link" */).then(c => wrapFunctional(c.default || c))
export const BaseDropdownList = () => import('../../components/base/BaseDropdownList.vue' /* webpackChunkName: "components/base-dropdown-list" */).then(c => wrapFunctional(c.default || c))
export const BaseExpandablePanel = () => import('../../components/base/BaseExpandablePanel.vue' /* webpackChunkName: "components/base-expandable-panel" */).then(c => wrapFunctional(c.default || c))
export const BaseFlag = () => import('../../components/base/BaseFlag.vue' /* webpackChunkName: "components/base-flag" */).then(c => wrapFunctional(c.default || c))
export const BaseHeading = () => import('../../components/base/BaseHeading.vue' /* webpackChunkName: "components/base-heading" */).then(c => wrapFunctional(c.default || c))
export const BaseHtmlContent = () => import('../../components/base/BaseHtmlContent.vue' /* webpackChunkName: "components/base-html-content" */).then(c => wrapFunctional(c.default || c))
export const BaseIcon = () => import('../../components/base/BaseIcon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseImage = () => import('../../components/base/BaseImage.vue' /* webpackChunkName: "components/base-image" */).then(c => wrapFunctional(c.default || c))
export const BaseLink = () => import('../../components/base/BaseLink.vue' /* webpackChunkName: "components/base-link" */).then(c => wrapFunctional(c.default || c))
export const BaseList = () => import('../../components/base/BaseList.vue' /* webpackChunkName: "components/base-list" */).then(c => wrapFunctional(c.default || c))
export const BaseLoader = () => import('../../components/base/BaseLoader.vue' /* webpackChunkName: "components/base-loader" */).then(c => wrapFunctional(c.default || c))
export const BaseMediclubContentBlock = () => import('../../components/base/BaseMediclubContentBlock.vue' /* webpackChunkName: "components/base-mediclub-content-block" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/base/BaseModal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BasePageTitle = () => import('../../components/base/BasePageTitle.vue' /* webpackChunkName: "components/base-page-title" */).then(c => wrapFunctional(c.default || c))
export const BasePagination = () => import('../../components/base/BasePagination.vue' /* webpackChunkName: "components/base-pagination" */).then(c => wrapFunctional(c.default || c))
export const BasePasswordInfo = () => import('../../components/base/BasePasswordInfo.vue' /* webpackChunkName: "components/base-password-info" */).then(c => wrapFunctional(c.default || c))
export const BaseProgressBar = () => import('../../components/base/BaseProgressBar.vue' /* webpackChunkName: "components/base-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const BaseSheet = () => import('../../components/base/BaseSheet.vue' /* webpackChunkName: "components/base-sheet" */).then(c => wrapFunctional(c.default || c))
export const BaseSpacer = () => import('../../components/base/BaseSpacer.vue' /* webpackChunkName: "components/base-spacer" */).then(c => wrapFunctional(c.default || c))
export const BaseStaticBlock = () => import('../../components/base/BaseStaticBlock.vue' /* webpackChunkName: "components/base-static-block" */).then(c => wrapFunctional(c.default || c))
export const BaseTable = () => import('../../components/base/BaseTable.vue' /* webpackChunkName: "components/base-table" */).then(c => wrapFunctional(c.default || c))
export const CategoryBanner = () => import('../../components/category/CategoryBanner.vue' /* webpackChunkName: "components/category-banner" */).then(c => wrapFunctional(c.default || c))
export const CategoryDiagnosticTests = () => import('../../components/category/CategoryDiagnosticTests.vue' /* webpackChunkName: "components/category-diagnostic-tests" */).then(c => wrapFunctional(c.default || c))
export const CategoryMobileButtons = () => import('../../components/category/CategoryMobileButtons.vue' /* webpackChunkName: "components/category-mobile-buttons" */).then(c => wrapFunctional(c.default || c))
export const CmsBanner = () => import('../../components/cms/CmsBanner.vue' /* webpackChunkName: "components/cms-banner" */).then(c => wrapFunctional(c.default || c))
export const CmsBrand = () => import('../../components/cms/CmsBrand.vue' /* webpackChunkName: "components/cms-brand" */).then(c => wrapFunctional(c.default || c))
export const CmsCategory = () => import('../../components/cms/CmsCategory.vue' /* webpackChunkName: "components/cms-category" */).then(c => wrapFunctional(c.default || c))
export const CmsDsa = () => import('../../components/cms/CmsDsa.vue' /* webpackChunkName: "components/cms-dsa" */).then(c => wrapFunctional(c.default || c))
export const CmsDsaPolicy = () => import('../../components/cms/CmsDsaPolicy.vue' /* webpackChunkName: "components/cms-dsa-policy" */).then(c => wrapFunctional(c.default || c))
export const CmsFooterLinks = () => import('../../components/cms/CmsFooterLinks.vue' /* webpackChunkName: "components/cms-footer-links" */).then(c => wrapFunctional(c.default || c))
export const CmsHelloBar = () => import('../../components/cms/CmsHelloBar.vue' /* webpackChunkName: "components/cms-hello-bar" */).then(c => wrapFunctional(c.default || c))
export const CmsIcon = () => import('../../components/cms/CmsIcon.vue' /* webpackChunkName: "components/cms-icon" */).then(c => wrapFunctional(c.default || c))
export const CmsProducts = () => import('../../components/cms/CmsProducts.vue' /* webpackChunkName: "components/cms-products" */).then(c => wrapFunctional(c.default || c))
export const CmsService = () => import('../../components/cms/CmsService.vue' /* webpackChunkName: "components/cms-service" */).then(c => wrapFunctional(c.default || c))
export const CmsTail = () => import('../../components/cms/CmsTail.vue' /* webpackChunkName: "components/cms-tail" */).then(c => wrapFunctional(c.default || c))
export const CmsVisits = () => import('../../components/cms/CmsVisits.vue' /* webpackChunkName: "components/cms-visits" */).then(c => wrapFunctional(c.default || c))
export const CustomFormAgreements = () => import('../../components/custom/CustomFormAgreements.vue' /* webpackChunkName: "components/custom-form-agreements" */).then(c => wrapFunctional(c.default || c))
export const CustomerBeneficiaryForm = () => import('../../components/customer/CustomerBeneficiaryForm.vue' /* webpackChunkName: "components/customer-beneficiary-form" */).then(c => wrapFunctional(c.default || c))
export const CustomerBeneficiaryFormRo = () => import('../../components/customer/CustomerBeneficiaryFormRo.vue' /* webpackChunkName: "components/customer-beneficiary-form-ro" */).then(c => wrapFunctional(c.default || c))
export const CustomerPageLoader = () => import('../../components/customer/CustomerPageLoader.vue' /* webpackChunkName: "components/customer-page-loader" */).then(c => wrapFunctional(c.default || c))
export const Error503 = () => import('../../components/error/Error503.vue' /* webpackChunkName: "components/error503" */).then(c => wrapFunctional(c.default || c))
export const Error5xx = () => import('../../components/error/Error5xx.vue' /* webpackChunkName: "components/error5xx" */).then(c => wrapFunctional(c.default || c))
export const ErrorDefault = () => import('../../components/error/ErrorDefault.vue' /* webpackChunkName: "components/error-default" */).then(c => wrapFunctional(c.default || c))
export const ErrorLanguageNotSupported = () => import('../../components/error/ErrorLanguageNotSupported.vue' /* webpackChunkName: "components/error-language-not-supported" */).then(c => wrapFunctional(c.default || c))
export const FooterCopyrights = () => import('../../components/footer/FooterCopyrights.vue' /* webpackChunkName: "components/footer-copyrights" */).then(c => wrapFunctional(c.default || c))
export const FooterLinkListWithHeading = () => import('../../components/footer/FooterLinkListWithHeading.vue' /* webpackChunkName: "components/footer-link-list-with-heading" */).then(c => wrapFunctional(c.default || c))
export const FooterScrollToTop = () => import('../../components/footer/FooterScrollToTop.vue' /* webpackChunkName: "components/footer-scroll-to-top" */).then(c => wrapFunctional(c.default || c))
export const ModalAgreement = () => import('../../components/modal/ModalAgreement.vue' /* webpackChunkName: "components/modal-agreement" */).then(c => wrapFunctional(c.default || c))
export const ModalCheckoutChangeDelivery = () => import('../../components/modal/ModalCheckoutChangeDelivery.vue' /* webpackChunkName: "components/modal-checkout-change-delivery" */).then(c => wrapFunctional(c.default || c))
export const ModalCheckoutProductRemove = () => import('../../components/modal/ModalCheckoutProductRemove.vue' /* webpackChunkName: "components/modal-checkout-product-remove" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirm = () => import('../../components/modal/ModalConfirm.vue' /* webpackChunkName: "components/modal-confirm" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirmToken = () => import('../../components/modal/ModalConfirmToken.vue' /* webpackChunkName: "components/modal-confirm-token" */).then(c => wrapFunctional(c.default || c))
export const ModalGoogleMaps = () => import('../../components/modal/ModalGoogleMaps.vue' /* webpackChunkName: "components/modal-google-maps" */).then(c => wrapFunctional(c.default || c))
export const ProductBlog = () => import('../../components/product/ProductBlog.vue' /* webpackChunkName: "components/product-blog" */).then(c => wrapFunctional(c.default || c))
export const ProductComposedProducts = () => import('../../components/product/ProductComposedProducts.vue' /* webpackChunkName: "components/product-composed-products" */).then(c => wrapFunctional(c.default || c))
export const ProductHorizontalTile = () => import('../../components/product/ProductHorizontalTile.vue' /* webpackChunkName: "components/product-horizontal-tile" */).then(c => wrapFunctional(c.default || c))
export const ProductLocation = () => import('../../components/product/ProductLocation.vue' /* webpackChunkName: "components/product-location" */).then(c => wrapFunctional(c.default || c))
export const ProductMaterialFee = () => import('../../components/product/ProductMaterialFee.vue' /* webpackChunkName: "components/product-material-fee" */).then(c => wrapFunctional(c.default || c))
export const ProductMediaGallery = () => import('../../components/product/ProductMediaGallery.vue' /* webpackChunkName: "components/product-media-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductPackagesOfProducts = () => import('../../components/product/ProductPackagesOfProducts.vue' /* webpackChunkName: "components/product-packages-of-products" */).then(c => wrapFunctional(c.default || c))
export const ProductPageItemsCarousel = () => import('../../components/product/ProductPageItemsCarousel.vue' /* webpackChunkName: "components/product-page-items-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProductPageTabs = () => import('../../components/product/ProductPageTabs.vue' /* webpackChunkName: "components/product-page-tabs" */).then(c => wrapFunctional(c.default || c))
export const ProductPageTabsAccordion = () => import('../../components/product/ProductPageTabsAccordion.vue' /* webpackChunkName: "components/product-page-tabs-accordion" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/product/ProductPrice.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductRelated = () => import('../../components/product/ProductRelated.vue' /* webpackChunkName: "components/product-related" */).then(c => wrapFunctional(c.default || c))
export const ProductRelatedSmall = () => import('../../components/product/ProductRelatedSmall.vue' /* webpackChunkName: "components/product-related-small" */).then(c => wrapFunctional(c.default || c))
export const ProductTablePrice = () => import('../../components/product/ProductTablePrice.vue' /* webpackChunkName: "components/product-table-price" */).then(c => wrapFunctional(c.default || c))
export const ProductTile = () => import('../../components/product/ProductTile.vue' /* webpackChunkName: "components/product-tile" */).then(c => wrapFunctional(c.default || c))
export const ProductTileAddToCartButton = () => import('../../components/product/ProductTileAddToCartButton.vue' /* webpackChunkName: "components/product-tile-add-to-cart-button" */).then(c => wrapFunctional(c.default || c))
export const ProductTileMini = () => import('../../components/product/ProductTileMini.vue' /* webpackChunkName: "components/product-tile-mini" */).then(c => wrapFunctional(c.default || c))
export const ProductTitle = () => import('../../components/product/ProductTitle.vue' /* webpackChunkName: "components/product-title" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantTile = () => import('../../components/product/ProductVariantTile.vue' /* webpackChunkName: "components/product-variant-tile" */).then(c => wrapFunctional(c.default || c))
export const QuestionnaireForm = () => import('../../components/questionnaire/QuestionnaireForm.vue' /* webpackChunkName: "components/questionnaire-form" */).then(c => wrapFunctional(c.default || c))
export const QuestionnaireFormAgreement = () => import('../../components/questionnaire/QuestionnaireFormAgreement.vue' /* webpackChunkName: "components/questionnaire-form-agreement" */).then(c => wrapFunctional(c.default || c))
export const QuestionnaireHeader = () => import('../../components/questionnaire/QuestionnaireHeader.vue' /* webpackChunkName: "components/questionnaire-header" */).then(c => wrapFunctional(c.default || c))
export const QuestionnaireInfoStaticBlock = () => import('../../components/questionnaire/QuestionnaireInfoStaticBlock.vue' /* webpackChunkName: "components/questionnaire-info-static-block" */).then(c => wrapFunctional(c.default || c))
export const QuestionnaireResult = () => import('../../components/questionnaire/QuestionnaireResult.vue' /* webpackChunkName: "components/questionnaire-result" */).then(c => wrapFunctional(c.default || c))
export const QuestionnaireSummary = () => import('../../components/questionnaire/QuestionnaireSummary.vue' /* webpackChunkName: "components/questionnaire-summary" */).then(c => wrapFunctional(c.default || c))
export const RatingForm = () => import('../../components/rating/RatingForm.vue' /* webpackChunkName: "components/rating-form" */).then(c => wrapFunctional(c.default || c))
export const RatingFormStars = () => import('../../components/rating/RatingFormStars.vue' /* webpackChunkName: "components/rating-form-stars" */).then(c => wrapFunctional(c.default || c))
export const RatingItem = () => import('../../components/rating/RatingItem.vue' /* webpackChunkName: "components/rating-item" */).then(c => wrapFunctional(c.default || c))
export const RatingPresentation = () => import('../../components/rating/RatingPresentation.vue' /* webpackChunkName: "components/rating-presentation" */).then(c => wrapFunctional(c.default || c))
export const RatingResponse = () => import('../../components/rating/RatingResponse.vue' /* webpackChunkName: "components/rating-response" */).then(c => wrapFunctional(c.default || c))
export const RatingReview = () => import('../../components/rating/RatingReview.vue' /* webpackChunkName: "components/rating-review" */).then(c => wrapFunctional(c.default || c))
export const RatingStar = () => import('../../components/rating/RatingStar.vue' /* webpackChunkName: "components/rating-star" */).then(c => wrapFunctional(c.default || c))
export const RatingSummary = () => import('../../components/rating/RatingSummary.vue' /* webpackChunkName: "components/rating-summary" */).then(c => wrapFunctional(c.default || c))
export const HeaderLogoContent = () => import('../../components/Header/Logo/HeaderLogoContent.vue' /* webpackChunkName: "components/header-logo-content" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenu = () => import('../../components/Header/Menu/HeaderMenu.vue' /* webpackChunkName: "components/header-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuIcon = () => import('../../components/Header/Menu/HeaderMenuIcon.vue' /* webpackChunkName: "components/header-menu-icon" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuItem = () => import('../../components/Header/Menu/HeaderMenuItem.vue' /* webpackChunkName: "components/header-menu-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderMinicartItem = () => import('../../components/Header/Minicart/HeaderMinicartItem.vue' /* webpackChunkName: "components/header-minicart-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderMinicartItemGrouped = () => import('../../components/Header/Minicart/HeaderMinicartItemGrouped.vue' /* webpackChunkName: "components/header-minicart-item-grouped" */).then(c => wrapFunctional(c.default || c))
export const HeaderMinicartItems = () => import('../../components/Header/Minicart/HeaderMinicartItems.vue' /* webpackChunkName: "components/header-minicart-items" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearch = () => import('../../components/Header/Search/HeaderSearch.vue' /* webpackChunkName: "components/header-search" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearchResults = () => import('../../components/Header/Search/HeaderSearchResults.vue' /* webpackChunkName: "components/header-search-results" */).then(c => wrapFunctional(c.default || c))
export const HeaderToast = () => import('../../components/Header/Toast/HeaderToast.vue' /* webpackChunkName: "components/header-toast" */).then(c => wrapFunctional(c.default || c))
export const HeaderToastItem = () => import('../../components/Header/Toast/HeaderToastItem.vue' /* webpackChunkName: "components/header-toast-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderButtonAccount = () => import('../../components/HeaderV2/Button/HeaderButtonAccount.vue' /* webpackChunkName: "components/header-button-account" */).then(c => wrapFunctional(c.default || c))
export const HeaderButtonCart = () => import('../../components/HeaderV2/Button/HeaderButtonCart.vue' /* webpackChunkName: "components/header-button-cart" */).then(c => wrapFunctional(c.default || c))
export const HeaderButtonMediclub = () => import('../../components/HeaderV2/Button/HeaderButtonMediclub.vue' /* webpackChunkName: "components/header-button-mediclub" */).then(c => wrapFunctional(c.default || c))
export const HomePageBuilder = () => import('../../components/HomePage/Builder/HomePageBuilder.vue' /* webpackChunkName: "components/home-page-builder" */).then(c => wrapFunctional(c.default || c))
export const HomePageAnimatedText = () => import('../../components/HomePage/Components/HomePageAnimatedText.vue' /* webpackChunkName: "components/home-page-animated-text" */).then(c => wrapFunctional(c.default || c))
export const HomePageBottomBanner = () => import('../../components/HomePage/Components/HomePageBottomBanner.vue' /* webpackChunkName: "components/home-page-bottom-banner" */).then(c => wrapFunctional(c.default || c))
export const HomePageCarousel = () => import('../../components/HomePage/Components/HomePageCarousel.vue' /* webpackChunkName: "components/home-page-carousel" */).then(c => wrapFunctional(c.default || c))
export const HomePageCarouselWithTabs = () => import('../../components/HomePage/Components/HomePageCarouselWithTabs.vue' /* webpackChunkName: "components/home-page-carousel-with-tabs" */).then(c => wrapFunctional(c.default || c))
export const HomePageDescription = () => import('../../components/HomePage/Components/HomePageDescription.vue' /* webpackChunkName: "components/home-page-description" */).then(c => wrapFunctional(c.default || c))
export const HomePageFourIcons = () => import('../../components/HomePage/Components/HomePageFourIcons.vue' /* webpackChunkName: "components/home-page-four-icons" */).then(c => wrapFunctional(c.default || c))
export const HomePageHeader = () => import('../../components/HomePage/Components/HomePageHeader.vue' /* webpackChunkName: "components/home-page-header" */).then(c => wrapFunctional(c.default || c))
export const HomePageImagesBanners = () => import('../../components/HomePage/Components/HomePageImagesBanners.vue' /* webpackChunkName: "components/home-page-images-banners" */).then(c => wrapFunctional(c.default || c))
export const HomePageMegaMenu = () => import('../../components/HomePage/Components/HomePageMegaMenu.vue' /* webpackChunkName: "components/home-page-mega-menu" */).then(c => wrapFunctional(c.default || c))
export const HomePageSearchWidget = () => import('../../components/HomePage/Components/HomePageSearchWidget.vue' /* webpackChunkName: "components/home-page-search-widget" */).then(c => wrapFunctional(c.default || c))
export const HomePageSlot = () => import('../../components/HomePage/Components/HomePageSlot.vue' /* webpackChunkName: "components/home-page-slot" */).then(c => wrapFunctional(c.default || c))
export const HomePageSteps = () => import('../../components/HomePage/Components/HomePageSteps.vue' /* webpackChunkName: "components/home-page-steps" */).then(c => wrapFunctional(c.default || c))
export const MediArticleHorizontalTile = () => import('../../components/Medi/Article/MediArticleHorizontalTile.vue' /* webpackChunkName: "components/medi-article-horizontal-tile" */).then(c => wrapFunctional(c.default || c))
export const MediArticleTime = () => import('../../components/Medi/Article/MediArticleTime.vue' /* webpackChunkName: "components/medi-article-time" */).then(c => wrapFunctional(c.default || c))
export const MediFooterStaticBlock = () => import('../../components/Medi/Article/MediFooterStaticBlock.vue' /* webpackChunkName: "components/medi-footer-static-block" */).then(c => wrapFunctional(c.default || c))
export const MediBlogArticleHeader = () => import('../../components/Medi/Blog/MediBlogArticleHeader.vue' /* webpackChunkName: "components/medi-blog-article-header" */).then(c => wrapFunctional(c.default || c))
export const MediBlogArticleInfo = () => import('../../components/Medi/Blog/MediBlogArticleInfo.vue' /* webpackChunkName: "components/medi-blog-article-info" */).then(c => wrapFunctional(c.default || c))
export const MediBlogArticleNav = () => import('../../components/Medi/Blog/MediBlogArticleNav.vue' /* webpackChunkName: "components/medi-blog-article-nav" */).then(c => wrapFunctional(c.default || c))
export const MediBlogArticleRelatedPosts = () => import('../../components/Medi/Blog/MediBlogArticleRelatedPosts.vue' /* webpackChunkName: "components/medi-blog-article-related-posts" */).then(c => wrapFunctional(c.default || c))
export const MediBlogArticleRelatedProducts = () => import('../../components/Medi/Blog/MediBlogArticleRelatedProducts.vue' /* webpackChunkName: "components/medi-blog-article-related-products" */).then(c => wrapFunctional(c.default || c))
export const MediBlogHeader = () => import('../../components/Medi/Blog/MediBlogHeader.vue' /* webpackChunkName: "components/medi-blog-header" */).then(c => wrapFunctional(c.default || c))
export const MediCategory = () => import('../../components/Medi/Category/MediCategory.vue' /* webpackChunkName: "components/medi-category" */).then(c => wrapFunctional(c.default || c))
export const MediCategoryEmpty = () => import('../../components/Medi/Category/MediCategoryEmpty.vue' /* webpackChunkName: "components/medi-category-empty" */).then(c => wrapFunctional(c.default || c))
export const MediModalAddToCart = () => import('../../components/Medi/Modal/MediModalAddToCart.vue' /* webpackChunkName: "components/medi-modal-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const MediModalVariantAddToCart = () => import('../../components/Medi/Modal/MediModalVariantAddToCart.vue' /* webpackChunkName: "components/medi-modal-variant-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const MediModalVariantAddedToCart = () => import('../../components/Medi/Modal/MediModalVariantAddedToCart.vue' /* webpackChunkName: "components/medi-modal-variant-added-to-cart" */).then(c => wrapFunctional(c.default || c))
export const MediProductLocation = () => import('../../components/Medi/Product/MediProductLocation.vue' /* webpackChunkName: "components/medi-product-location" */).then(c => wrapFunctional(c.default || c))
export const MediProductTileAddToCartButton = () => import('../../components/Medi/Product/MediProductTileAddToCartButton.vue' /* webpackChunkName: "components/medi-product-tile-add-to-cart-button" */).then(c => wrapFunctional(c.default || c))
export const MediSelect = () => import('../../components/Medi/Select/MediSelect.vue' /* webpackChunkName: "components/medi-select" */).then(c => wrapFunctional(c.default || c))
export const MediSelectCheckbox = () => import('../../components/Medi/Select/MediSelectCheckbox.vue' /* webpackChunkName: "components/medi-select-checkbox" */).then(c => wrapFunctional(c.default || c))
export const MediSelectCheckboxList = () => import('../../components/Medi/Select/MediSelectCheckboxList.vue' /* webpackChunkName: "components/medi-select-checkbox-list" */).then(c => wrapFunctional(c.default || c))
export const MediSelectCheckboxModal = () => import('../../components/Medi/Select/MediSelectCheckboxModal.vue' /* webpackChunkName: "components/medi-select-checkbox-modal" */).then(c => wrapFunctional(c.default || c))
export const MediSelectList = () => import('../../components/Medi/Select/MediSelectList.vue' /* webpackChunkName: "components/medi-select-list" */).then(c => wrapFunctional(c.default || c))
export const MediSelectModal = () => import('../../components/Medi/Select/MediSelectModal.vue' /* webpackChunkName: "components/medi-select-modal" */).then(c => wrapFunctional(c.default || c))
export const MediSelectScrollLoader = () => import('../../components/Medi/Select/MediSelectScrollLoader.vue' /* webpackChunkName: "components/medi-select-scroll-loader" */).then(c => wrapFunctional(c.default || c))
export const MediSelectScrollLoaderList = () => import('../../components/Medi/Select/MediSelectScrollLoaderList.vue' /* webpackChunkName: "components/medi-select-scroll-loader-list" */).then(c => wrapFunctional(c.default || c))
export const MediSelectScrollLoaderModal = () => import('../../components/Medi/Select/MediSelectScrollLoaderModal.vue' /* webpackChunkName: "components/medi-select-scroll-loader-modal" */).then(c => wrapFunctional(c.default || c))
export const MediSnapCarousel = () => import('../../components/Medi/SnapCarousel/MediSnapCarousel.vue' /* webpackChunkName: "components/medi-snap-carousel" */).then(c => wrapFunctional(c.default || c))
export const MediSnapCarouselHomePage = () => import('../../components/Medi/SnapCarousel/MediSnapCarouselHomePage.vue' /* webpackChunkName: "components/medi-snap-carousel-home-page" */).then(c => wrapFunctional(c.default || c))
export const MediSnapCarouselHomePageTabs = () => import('../../components/Medi/SnapCarousel/MediSnapCarouselHomePageTabs.vue' /* webpackChunkName: "components/medi-snap-carousel-home-page-tabs" */).then(c => wrapFunctional(c.default || c))
export const MediSnapCarouselHomePageTitle = () => import('../../components/Medi/SnapCarousel/MediSnapCarouselHomePageTitle.vue' /* webpackChunkName: "components/medi-snap-carousel-home-page-title" */).then(c => wrapFunctional(c.default || c))
export const MediTabs = () => import('../../components/Medi/Tabs/MediTabs.vue' /* webpackChunkName: "components/medi-tabs" */).then(c => wrapFunctional(c.default || c))
export const MediTabsItem = () => import('../../components/Medi/Tabs/MediTabsItem.vue' /* webpackChunkName: "components/medi-tabs-item" */).then(c => wrapFunctional(c.default || c))
export const MediTileBlog = () => import('../../components/Medi/Tile/MediTileBlog.vue' /* webpackChunkName: "components/medi-tile-blog" */).then(c => wrapFunctional(c.default || c))
export const MediTileBrand = () => import('../../components/Medi/Tile/MediTileBrand.vue' /* webpackChunkName: "components/medi-tile-brand" */).then(c => wrapFunctional(c.default || c))
export const MediTileProduct = () => import('../../components/Medi/Tile/MediTileProduct.vue' /* webpackChunkName: "components/medi-tile-product" */).then(c => wrapFunctional(c.default || c))
export const MediTileProductHorizontal = () => import('../../components/Medi/Tile/MediTileProductHorizontal.vue' /* webpackChunkName: "components/medi-tile-product-horizontal" */).then(c => wrapFunctional(c.default || c))
export const MediTileSpecialization = () => import('../../components/Medi/Tile/MediTileSpecialization.vue' /* webpackChunkName: "components/medi-tile-specialization" */).then(c => wrapFunctional(c.default || c))
export const MediWidgetButton = () => import('../../components/Medi/Widget/MediWidgetButton.vue' /* webpackChunkName: "components/medi-widget-button" */).then(c => wrapFunctional(c.default || c))
export const MediWidgetContainer = () => import('../../components/Medi/Widget/MediWidgetContainer.vue' /* webpackChunkName: "components/medi-widget-container" */).then(c => wrapFunctional(c.default || c))
export const MediWidgetSelect = () => import('../../components/Medi/Widget/MediWidgetSelect.vue' /* webpackChunkName: "components/medi-widget-select" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuAccountMobile = () => import('../../components/MegaMenu/Account/MegaMenuAccountMobile.vue' /* webpackChunkName: "components/mega-menu-account-mobile" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuAccountMobileBtn = () => import('../../components/MegaMenu/Account/MegaMenuAccountMobileBtn.vue' /* webpackChunkName: "components/mega-menu-account-mobile-btn" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuSearch = () => import('../../components/MegaMenu/Search/MegaMenuSearch.vue' /* webpackChunkName: "components/mega-menu-search" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuSearchResults = () => import('../../components/MegaMenu/Search/MegaMenuSearchResults.vue' /* webpackChunkName: "components/mega-menu-search-results" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuSearchNewLook = () => import('../../components/MegaMenu/SearchNewLook/MegaMenuSearchNewLook.vue' /* webpackChunkName: "components/mega-menu-search-new-look" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuSearchNewLookResults = () => import('../../components/MegaMenu/SearchNewLook/MegaMenuSearchNewLookResults.vue' /* webpackChunkName: "components/mega-menu-search-new-look-results" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuSheetDesktop = () => import('../../components/MegaMenu/Sheet/MegaMenuSheetDesktop.vue' /* webpackChunkName: "components/mega-menu-sheet-desktop" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuSheetMobile = () => import('../../components/MegaMenu/Sheet/MegaMenuSheetMobile.vue' /* webpackChunkName: "components/mega-menu-sheet-mobile" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuSheetSeo = () => import('../../components/MegaMenu/Sheet/MegaMenuSheetSeo.vue' /* webpackChunkName: "components/mega-menu-sheet-seo" */).then(c => wrapFunctional(c.default || c))
export const AccountLoginGuestBtn = () => import('../../components/account/login/AccountLoginGuestBtn.vue' /* webpackChunkName: "components/account-login-guest-btn" */).then(c => wrapFunctional(c.default || c))
export const AccountLoginHeader = () => import('../../components/account/login/AccountLoginHeader.vue' /* webpackChunkName: "components/account-login-header" */).then(c => wrapFunctional(c.default || c))
export const AccountLoginRegisterBtn = () => import('../../components/account/login/AccountLoginRegisterBtn.vue' /* webpackChunkName: "components/account-login-register-btn" */).then(c => wrapFunctional(c.default || c))
export const AccountNav = () => import('../../components/account/nav/AccountNav.vue' /* webpackChunkName: "components/account-nav" */).then(c => wrapFunctional(c.default || c))
export const AccountRegisterBanner = () => import('../../components/account/register/AccountRegisterBanner.vue' /* webpackChunkName: "components/account-register-banner" */).then(c => wrapFunctional(c.default || c))
export const BaseCarousel = () => import('../../components/base/carousel/BaseCarousel.vue' /* webpackChunkName: "components/base-carousel" */).then(c => wrapFunctional(c.default || c))
export const BaseForm = () => import('../../components/base/form/BaseForm.vue' /* webpackChunkName: "components/base-form" */).then(c => wrapFunctional(c.default || c))
export const BaseFormBuilder = () => import('../../components/base/form/BaseFormBuilder.vue' /* webpackChunkName: "components/base-form-builder" */).then(c => wrapFunctional(c.default || c))
export const BaseFormCheckbox = () => import('../../components/base/form/BaseFormCheckbox.vue' /* webpackChunkName: "components/base-form-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseFormCountry = () => import('../../components/base/form/BaseFormCountry.vue' /* webpackChunkName: "components/base-form-country" */).then(c => wrapFunctional(c.default || c))
export const BaseFormDatepicker = () => import('../../components/base/form/BaseFormDatepicker.vue' /* webpackChunkName: "components/base-form-datepicker" */).then(c => wrapFunctional(c.default || c))
export const BaseFormField = () => import('../../components/base/form/BaseFormField.vue' /* webpackChunkName: "components/base-form-field" */).then(c => wrapFunctional(c.default || c))
export const BaseFormFile = () => import('../../components/base/form/BaseFormFile.vue' /* webpackChunkName: "components/base-form-file" */).then(c => wrapFunctional(c.default || c))
export const BaseFormInput = () => import('../../components/base/form/BaseFormInput.vue' /* webpackChunkName: "components/base-form-input" */).then(c => wrapFunctional(c.default || c))
export const BaseFormLabel = () => import('../../components/base/form/BaseFormLabel.vue' /* webpackChunkName: "components/base-form-label" */).then(c => wrapFunctional(c.default || c))
export const BaseFormMessage = () => import('../../components/base/form/BaseFormMessage.vue' /* webpackChunkName: "components/base-form-message" */).then(c => wrapFunctional(c.default || c))
export const BaseFormNip = () => import('../../components/base/form/BaseFormNip.vue' /* webpackChunkName: "components/base-form-nip" */).then(c => wrapFunctional(c.default || c))
export const BaseFormNumberInput = () => import('../../components/base/form/BaseFormNumberInput.vue' /* webpackChunkName: "components/base-form-number-input" */).then(c => wrapFunctional(c.default || c))
export const BaseFormPassword = () => import('../../components/base/form/BaseFormPassword.vue' /* webpackChunkName: "components/base-form-password" */).then(c => wrapFunctional(c.default || c))
export const BaseFormPaymentMethod = () => import('../../components/base/form/BaseFormPaymentMethod.vue' /* webpackChunkName: "components/base-form-payment-method" */).then(c => wrapFunctional(c.default || c))
export const BaseFormPhone = () => import('../../components/base/form/BaseFormPhone.vue' /* webpackChunkName: "components/base-form-phone" */).then(c => wrapFunctional(c.default || c))
export const BaseFormRadioButton = () => import('../../components/base/form/BaseFormRadioButton.vue' /* webpackChunkName: "components/base-form-radio-button" */).then(c => wrapFunctional(c.default || c))
export const BaseFormRadioSwitcher = () => import('../../components/base/form/BaseFormRadioSwitcher.vue' /* webpackChunkName: "components/base-form-radio-switcher" */).then(c => wrapFunctional(c.default || c))
export const BaseFormScope = () => import('../../components/base/form/BaseFormScope.vue' /* webpackChunkName: "components/base-form-scope" */).then(c => wrapFunctional(c.default || c))
export const BaseFormSelect = () => import('../../components/base/form/BaseFormSelect.vue' /* webpackChunkName: "components/base-form-select" */).then(c => wrapFunctional(c.default || c))
export const BaseFormState = () => import('../../components/base/form/BaseFormState.vue' /* webpackChunkName: "components/base-form-state" */).then(c => wrapFunctional(c.default || c))
export const BaseFormTextarea = () => import('../../components/base/form/BaseFormTextarea.vue' /* webpackChunkName: "components/base-form-textarea" */).then(c => wrapFunctional(c.default || c))
export const BaseFormAvailableFormFields = () => import('../../components/base/form/availableFormFields.js' /* webpackChunkName: "components/base-form-available-form-fields" */).then(c => wrapFunctional(c.default || c))
export const BaseHtmlCode = () => import('../../components/base/htmlContent/BaseHtmlCode.vue' /* webpackChunkName: "components/base-html-code" */).then(c => wrapFunctional(c.default || c))
export const BaseSidebar = () => import('../../components/base/sidebar/BaseSidebar.vue' /* webpackChunkName: "components/base-sidebar" */).then(c => wrapFunctional(c.default || c))
export const BaseSidebarItem = () => import('../../components/base/sidebar/BaseSidebarItem.vue' /* webpackChunkName: "components/base-sidebar-item" */).then(c => wrapFunctional(c.default || c))
export const BaseTabs = () => import('../../components/base/tabs/BaseTabs.vue' /* webpackChunkName: "components/base-tabs" */).then(c => wrapFunctional(c.default || c))
export const BaseTabsAccordion = () => import('../../components/base/tabs/BaseTabsAccordion.vue' /* webpackChunkName: "components/base-tabs-accordion" */).then(c => wrapFunctional(c.default || c))
export const BaseTabsItem = () => import('../../components/base/tabs/BaseTabsItem.vue' /* webpackChunkName: "components/base-tabs-item" */).then(c => wrapFunctional(c.default || c))
export const BaseToast = () => import('../../components/base/toast/BaseToast.vue' /* webpackChunkName: "components/base-toast" */).then(c => wrapFunctional(c.default || c))
export const BaseToastItem = () => import('../../components/base/toast/BaseToastItem.vue' /* webpackChunkName: "components/base-toast-item" */).then(c => wrapFunctional(c.default || c))
export const BaseTooltipMedi = () => import('../../components/base/tooltip/BaseTooltipMedi.vue' /* webpackChunkName: "components/base-tooltip-medi" */).then(c => wrapFunctional(c.default || c))
export const CategoryFilter = () => import('../../components/category/filter/CategoryFilter.vue' /* webpackChunkName: "components/category-filter" */).then(c => wrapFunctional(c.default || c))
export const CategoryFilterMobileBtn = () => import('../../components/category/filter/CategoryFilterMobileBtn.vue' /* webpackChunkName: "components/category-filter-mobile-btn" */).then(c => wrapFunctional(c.default || c))
export const CategoryFilterPopularDoctors = () => import('../../components/category/filter/CategoryFilterPopularDoctors.vue' /* webpackChunkName: "components/category-filter-popular-doctors" */).then(c => wrapFunctional(c.default || c))
export const CategoryListsDefault = () => import('../../components/category/lists/CategoryListsDefault.vue' /* webpackChunkName: "components/category-lists-default" */).then(c => wrapFunctional(c.default || c))
export const CategoryListsScrollable = () => import('../../components/category/lists/CategoryListsScrollable.vue' /* webpackChunkName: "components/category-lists-scrollable" */).then(c => wrapFunctional(c.default || c))
export const CategoryListsStaticBlockOnly = () => import('../../components/category/lists/CategoryListsStaticBlockOnly.vue' /* webpackChunkName: "components/category-lists-static-block-only" */).then(c => wrapFunctional(c.default || c))
export const CategoryListsTestsAZ = () => import('../../components/category/lists/CategoryListsTestsAZ.vue' /* webpackChunkName: "components/category-lists-tests-a-z" */).then(c => wrapFunctional(c.default || c))
export const CategorySort = () => import('../../components/category/sort/CategorySort.vue' /* webpackChunkName: "components/category-sort" */).then(c => wrapFunctional(c.default || c))
export const CmsBaseElements = () => import('../../components/cms/base/CmsBaseElements.vue' /* webpackChunkName: "components/cms-base-elements" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubFaq = () => import('../../components/cms/mediclub/CmsMediclubFaq.vue' /* webpackChunkName: "components/cms-mediclub-faq" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubReasons = () => import('../../components/cms/mediclub/CmsMediclubReasons.vue' /* webpackChunkName: "components/cms-mediclub-reasons" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubWelcome = () => import('../../components/cms/mediclub/CmsMediclubWelcome.vue' /* webpackChunkName: "components/cms-mediclub-welcome" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubWorks = () => import('../../components/cms/mediclub/CmsMediclubWorks.vue' /* webpackChunkName: "components/cms-mediclub-works" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubYoutube = () => import('../../components/cms/mediclub/CmsMediclubYoutube.vue' /* webpackChunkName: "components/cms-mediclub-youtube" */).then(c => wrapFunctional(c.default || c))
export const CustomerProfile = () => import('../../components/customer/profile/CustomerProfile.vue' /* webpackChunkName: "components/customer-profile" */).then(c => wrapFunctional(c.default || c))
export const CustomerProfileRo = () => import('../../components/customer/profile/CustomerProfileRo.vue' /* webpackChunkName: "components/customer-profile-ro" */).then(c => wrapFunctional(c.default || c))
export const FooterContact = () => import('../../components/footer/V2/FooterContact.vue' /* webpackChunkName: "components/footer-contact" */).then(c => wrapFunctional(c.default || c))
export const FooterHeading = () => import('../../components/footer/V2/FooterHeading.vue' /* webpackChunkName: "components/footer-heading" */).then(c => wrapFunctional(c.default || c))
export const FooterLine = () => import('../../components/footer/V2/FooterLine.vue' /* webpackChunkName: "components/footer-line" */).then(c => wrapFunctional(c.default || c))
export const FooterLocale = () => import('../../components/footer/V2/FooterLocale.vue' /* webpackChunkName: "components/footer-locale" */).then(c => wrapFunctional(c.default || c))
export const FooterSection = () => import('../../components/footer/V2/FooterSection.vue' /* webpackChunkName: "components/footer-section" */).then(c => wrapFunctional(c.default || c))
export const FooterSocials = () => import('../../components/footer/V2/FooterSocials.vue' /* webpackChunkName: "components/footer-socials" */).then(c => wrapFunctional(c.default || c))
export const ProductAddedToCartModal = () => import('../../components/product/Modal/ProductAddedToCartModal.vue' /* webpackChunkName: "components/product-added-to-cart-modal" */).then(c => wrapFunctional(c.default || c))
export const ProductModalAddToCart = () => import('../../components/product/Modal/ProductModalAddToCart.vue' /* webpackChunkName: "components/product-modal-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const ProductAppointmentCalendar = () => import('../../components/product/appointment/ProductAppointmentCalendar.vue' /* webpackChunkName: "components/product-appointment-calendar" */).then(c => wrapFunctional(c.default || c))
export const ProductAppointmentDateTile = () => import('../../components/product/appointment/ProductAppointmentDateTile.vue' /* webpackChunkName: "components/product-appointment-date-tile" */).then(c => wrapFunctional(c.default || c))
export const ProductAppointmentFilters = () => import('../../components/product/appointment/ProductAppointmentFilters.vue' /* webpackChunkName: "components/product-appointment-filters" */).then(c => wrapFunctional(c.default || c))
export const ProductAppointmentNotice = () => import('../../components/product/appointment/ProductAppointmentNotice.vue' /* webpackChunkName: "components/product-appointment-notice" */).then(c => wrapFunctional(c.default || c))
export const ProductAppointmentSlot = () => import('../../components/product/appointment/ProductAppointmentSlot.vue' /* webpackChunkName: "components/product-appointment-slot" */).then(c => wrapFunctional(c.default || c))
export const ProductAsideStatic = () => import('../../components/product/aside/ProductAsideStatic.vue' /* webpackChunkName: "components/product-aside-static" */).then(c => wrapFunctional(c.default || c))
export const ProductContainerDefault = () => import('../../components/product/container/ProductContainerDefault.vue' /* webpackChunkName: "components/product-container-default" */).then(c => wrapFunctional(c.default || c))
export const ProductContainerPhysical = () => import('../../components/product/container/ProductContainerPhysical.vue' /* webpackChunkName: "components/product-container-physical" */).then(c => wrapFunctional(c.default || c))
export const ProductFilterVariantLocation = () => import('../../components/product/filter/ProductFilterVariantLocation.vue' /* webpackChunkName: "components/product-filter-variant-location" */).then(c => wrapFunctional(c.default || c))
export const ProductFooterVirtualCategoryLinks = () => import('../../components/product/footer/ProductFooterVirtualCategoryLinks.vue' /* webpackChunkName: "components/product-footer-virtual-category-links" */).then(c => wrapFunctional(c.default || c))
export const ProductPhysicalCtaButton = () => import('../../components/product/physical/ProductPhysicalCtaButton.vue' /* webpackChunkName: "components/product-physical-cta-button" */).then(c => wrapFunctional(c.default || c))
export const ProductPhysicalDeliveryOnCard = () => import('../../components/product/physical/ProductPhysicalDeliveryOnCard.vue' /* webpackChunkName: "components/product-physical-delivery-on-card" */).then(c => wrapFunctional(c.default || c))
export const ProductPhysicalDescription = () => import('../../components/product/physical/ProductPhysicalDescription.vue' /* webpackChunkName: "components/product-physical-description" */).then(c => wrapFunctional(c.default || c))
export const ProductPhysicalIcons = () => import('../../components/product/physical/ProductPhysicalIcons.vue' /* webpackChunkName: "components/product-physical-icons" */).then(c => wrapFunctional(c.default || c))
export const ProductPhysicalInfoOnCard = () => import('../../components/product/physical/ProductPhysicalInfoOnCard.vue' /* webpackChunkName: "components/product-physical-info-on-card" */).then(c => wrapFunctional(c.default || c))
export const ProductPhysicalMain = () => import('../../components/product/physical/ProductPhysicalMain.vue' /* webpackChunkName: "components/product-physical-main" */).then(c => wrapFunctional(c.default || c))
export const ProductPhysicalPrice = () => import('../../components/product/physical/ProductPhysicalPrice.vue' /* webpackChunkName: "components/product-physical-price" */).then(c => wrapFunctional(c.default || c))
export const ProductPhysicalTitle = () => import('../../components/product/physical/ProductPhysicalTitle.vue' /* webpackChunkName: "components/product-physical-title" */).then(c => wrapFunctional(c.default || c))
export const ProductPhysicalVariant = () => import('../../components/product/physical/ProductPhysicalVariant.vue' /* webpackChunkName: "components/product-physical-variant" */).then(c => wrapFunctional(c.default || c))
export const ProductTypesAppointment = () => import('../../components/product/types/ProductTypesAppointment.vue' /* webpackChunkName: "components/product-types-appointment" */).then(c => wrapFunctional(c.default || c))
export const ProductTypesAppointmentNew = () => import('../../components/product/types/ProductTypesAppointmentNew.vue' /* webpackChunkName: "components/product-types-appointment-new" */).then(c => wrapFunctional(c.default || c))
export const ProductTypesLenses = () => import('../../components/product/types/ProductTypesLenses.vue' /* webpackChunkName: "components/product-types-lenses" */).then(c => wrapFunctional(c.default || c))
export const ProductTypesMedicalPackage = () => import('../../components/product/types/ProductTypesMedicalPackage.vue' /* webpackChunkName: "components/product-types-medical-package" */).then(c => wrapFunctional(c.default || c))
export const ProductTypesPackagedService = () => import('../../components/product/types/ProductTypesPackagedService.vue' /* webpackChunkName: "components/product-types-packaged-service" */).then(c => wrapFunctional(c.default || c))
export const ProductTypesPrepaidCard = () => import('../../components/product/types/ProductTypesPrepaidCard.vue' /* webpackChunkName: "components/product-types-prepaid-card" */).then(c => wrapFunctional(c.default || c))
export const ProductTypesSimple = () => import('../../components/product/types/ProductTypesSimple.vue' /* webpackChunkName: "components/product-types-simple" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenuAccountDesktop = () => import('../../components/Header/Menu/Account/HeaderMenuAccountDesktop.vue' /* webpackChunkName: "components/header-menu-account-desktop" */).then(c => wrapFunctional(c.default || c))
export const HeaderMinicartMediItem = () => import('../../components/Header/Minicart/Medi/HeaderMinicartMediItem.vue' /* webpackChunkName: "components/header-minicart-medi-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderMinicartMediItemGrouped = () => import('../../components/Header/Minicart/Medi/HeaderMinicartMediItemGrouped.vue' /* webpackChunkName: "components/header-minicart-medi-item-grouped" */).then(c => wrapFunctional(c.default || c))
export const HeaderMinicartMediItemRemove = () => import('../../components/Header/Minicart/Medi/HeaderMinicartMediItemRemove.vue' /* webpackChunkName: "components/header-minicart-medi-item-remove" */).then(c => wrapFunctional(c.default || c))
export const HeaderButtonMenuAccount = () => import('../../components/HeaderV2/Button/Menu/HeaderButtonMenuAccount.vue' /* webpackChunkName: "components/header-button-menu-account" */).then(c => wrapFunctional(c.default || c))
export const HeaderButtonMenuCart = () => import('../../components/HeaderV2/Button/Menu/HeaderButtonMenuCart.vue' /* webpackChunkName: "components/header-button-menu-cart" */).then(c => wrapFunctional(c.default || c))
export const HomePageVirtualCategories = () => import('../../components/HomePage/Components/VirtualCategories/HomePageVirtualCategories.vue' /* webpackChunkName: "components/home-page-virtual-categories" */).then(c => wrapFunctional(c.default || c))
export const HomePageVirtualCategoriesColumn = () => import('../../components/HomePage/Components/VirtualCategories/HomePageVirtualCategoriesColumn.vue' /* webpackChunkName: "components/home-page-virtual-categories-column" */).then(c => wrapFunctional(c.default || c))
export const HomePageVirtualCategoriesItem = () => import('../../components/HomePage/Components/VirtualCategories/HomePageVirtualCategoriesItem.vue' /* webpackChunkName: "components/home-page-virtual-categories-item" */).then(c => wrapFunctional(c.default || c))
export const HomePageVirtualCategoriesList = () => import('../../components/HomePage/Components/VirtualCategories/HomePageVirtualCategoriesList.vue' /* webpackChunkName: "components/home-page-virtual-categories-list" */).then(c => wrapFunctional(c.default || c))
export const MediBlogMainCarousel = () => import('../../components/Medi/Blog/Main/MediBlogMainCarousel.vue' /* webpackChunkName: "components/medi-blog-main-carousel" */).then(c => wrapFunctional(c.default || c))
export const MediCategoryFilterBelt = () => import('../../components/Medi/Category/Filters/MediCategoryFilterBelt.vue' /* webpackChunkName: "components/medi-category-filter-belt" */).then(c => wrapFunctional(c.default || c))
export const MediCategoryFilterList = () => import('../../components/Medi/Category/Filters/MediCategoryFilterList.vue' /* webpackChunkName: "components/medi-category-filter-list" */).then(c => wrapFunctional(c.default || c))
export const MediCategoryFilterModalContent = () => import('../../components/Medi/Category/Filters/MediCategoryFilterModalContent.vue' /* webpackChunkName: "components/medi-category-filter-modal-content" */).then(c => wrapFunctional(c.default || c))
export const MediCategoryFilterSideBar = () => import('../../components/Medi/Category/Filters/MediCategoryFilterSideBar.vue' /* webpackChunkName: "components/medi-category-filter-side-bar" */).then(c => wrapFunctional(c.default || c))
export const MediCategoryFilterSubCategory = () => import('../../components/Medi/Category/Filters/MediCategoryFilterSubCategory.vue' /* webpackChunkName: "components/medi-category-filter-sub-category" */).then(c => wrapFunctional(c.default || c))
export const MediCategoryFooterBanner = () => import('../../components/Medi/Category/Footer/MediCategoryFooterBanner.vue' /* webpackChunkName: "components/medi-category-footer-banner" */).then(c => wrapFunctional(c.default || c))
export const MediCategorySponsored = () => import('../../components/Medi/Category/Sponsored/MediCategorySponsored.vue' /* webpackChunkName: "components/medi-category-sponsored" */).then(c => wrapFunctional(c.default || c))
export const MediCategorySponsoredItem = () => import('../../components/Medi/Category/Sponsored/MediCategorySponsoredItem.vue' /* webpackChunkName: "components/medi-category-sponsored-item" */).then(c => wrapFunctional(c.default || c))
export const MediProductTypesAppointment = () => import('../../components/Medi/Product/Types/MediProductTypesAppointment.vue' /* webpackChunkName: "components/medi-product-types-appointment" */).then(c => wrapFunctional(c.default || c))
export const MediProductTypesAppointmentNew = () => import('../../components/Medi/Product/Types/MediProductTypesAppointmentNew.vue' /* webpackChunkName: "components/medi-product-types-appointment-new" */).then(c => wrapFunctional(c.default || c))
export const MediProductTypesLenses = () => import('../../components/Medi/Product/Types/MediProductTypesLenses.vue' /* webpackChunkName: "components/medi-product-types-lenses" */).then(c => wrapFunctional(c.default || c))
export const MediProductTypesMedicalPackage = () => import('../../components/Medi/Product/Types/MediProductTypesMedicalPackage.vue' /* webpackChunkName: "components/medi-product-types-medical-package" */).then(c => wrapFunctional(c.default || c))
export const MediProductTypesPackagedService = () => import('../../components/Medi/Product/Types/MediProductTypesPackagedService.vue' /* webpackChunkName: "components/medi-product-types-packaged-service" */).then(c => wrapFunctional(c.default || c))
export const MediProductTypesPrepaidCard = () => import('../../components/Medi/Product/Types/MediProductTypesPrepaidCard.vue' /* webpackChunkName: "components/medi-product-types-prepaid-card" */).then(c => wrapFunctional(c.default || c))
export const MediProductTypesService = () => import('../../components/Medi/Product/Types/MediProductTypesService.vue' /* webpackChunkName: "components/medi-product-types-service" */).then(c => wrapFunctional(c.default || c))
export const MediProductTypesSimple = () => import('../../components/Medi/Product/Types/MediProductTypesSimple.vue' /* webpackChunkName: "components/medi-product-types-simple" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuSheetDesktopItem = () => import('../../components/MegaMenu/Sheet/Desktop/MegaMenuSheetDesktopItem.vue' /* webpackChunkName: "components/mega-menu-sheet-desktop-item" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuSheetDesktopLink = () => import('../../components/MegaMenu/Sheet/Desktop/MegaMenuSheetDesktopLink.vue' /* webpackChunkName: "components/mega-menu-sheet-desktop-link" */).then(c => wrapFunctional(c.default || c))
export const BaseFormActions = () => import('../../components/base/form/actions/BaseFormActions.vue' /* webpackChunkName: "components/base-form-actions" */).then(c => wrapFunctional(c.default || c))
export const BaseFormActionsDefault = () => import('../../components/base/form/actions/BaseFormActionsDefault.vue' /* webpackChunkName: "components/base-form-actions-default" */).then(c => wrapFunctional(c.default || c))
export const BaseFormActionsPage = () => import('../../components/base/form/actions/BaseFormActionsPage.vue' /* webpackChunkName: "components/base-form-actions-page" */).then(c => wrapFunctional(c.default || c))
export const BaseFormCheckboxExpandable = () => import('../../components/base/form/checkboxexpandable/BaseFormCheckboxExpandable.vue' /* webpackChunkName: "components/base-form-checkbox-expandable" */).then(c => wrapFunctional(c.default || c))
export const BaseFormDatepickerDesktop = () => import('../../components/base/form/datepicker/BaseFormDatepickerDesktop.vue' /* webpackChunkName: "components/base-form-datepicker-desktop" */).then(c => wrapFunctional(c.default || c))
export const BaseFormDatepickerMobile = () => import('../../components/base/form/datepicker/BaseFormDatepickerMobile.vue' /* webpackChunkName: "components/base-form-datepicker-mobile" */).then(c => wrapFunctional(c.default || c))
export const BaseFormGroupedCheckbox = () => import('../../components/base/form/grouped/BaseFormGroupedCheckbox.vue' /* webpackChunkName: "components/base-form-grouped-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseFormPoolChildrenArray = () => import('../../components/base/form/pool/BaseFormPoolChildrenArray.vue' /* webpackChunkName: "components/base-form-pool-children-array" */).then(c => wrapFunctional(c.default || c))
export const BaseFormSelectList = () => import('../../components/base/form/select/BaseFormSelectList.vue' /* webpackChunkName: "components/base-form-select-list" */).then(c => wrapFunctional(c.default || c))
export const BaseFormSelectModal = () => import('../../components/base/form/select/BaseFormSelectModal.vue' /* webpackChunkName: "components/base-form-select-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseFormValidateGroupedCheckbox = () => import('../../components/base/form/validate/BaseFormValidateGroupedCheckbox.vue' /* webpackChunkName: "components/base-form-validate-grouped-checkbox" */).then(c => wrapFunctional(c.default || c))
export const CategoryFilterModalMobile = () => import('../../components/category/filter/modal/CategoryFilterModalMobile.vue' /* webpackChunkName: "components/category-filter-modal-mobile" */).then(c => wrapFunctional(c.default || c))
export const CategoryListsBottomDesc = () => import('../../components/category/lists/bottomDesc/CategoryListsBottomDesc.vue' /* webpackChunkName: "components/category-lists-bottom-desc" */).then(c => wrapFunctional(c.default || c))
export const CategorySortModalMobile = () => import('../../components/category/sort/modal/CategorySortModalMobile.vue' /* webpackChunkName: "components/category-sort-modal-mobile" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubBlock1 = () => import('../../components/cms/mediclub/v2/CmsMediclubBlock1.vue' /* webpackChunkName: "components/cms-mediclub-block1" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubBlock2 = () => import('../../components/cms/mediclub/v2/CmsMediclubBlock2.vue' /* webpackChunkName: "components/cms-mediclub-block2" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubBlock3 = () => import('../../components/cms/mediclub/v2/CmsMediclubBlock3.vue' /* webpackChunkName: "components/cms-mediclub-block3" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubBlock4 = () => import('../../components/cms/mediclub/v2/CmsMediclubBlock4.vue' /* webpackChunkName: "components/cms-mediclub-block4" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubBlock5 = () => import('../../components/cms/mediclub/v2/CmsMediclubBlock5.vue' /* webpackChunkName: "components/cms-mediclub-block5" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubBlock6 = () => import('../../components/cms/mediclub/v2/CmsMediclubBlock6.vue' /* webpackChunkName: "components/cms-mediclub-block6" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubBlock7 = () => import('../../components/cms/mediclub/v2/CmsMediclubBlock7.vue' /* webpackChunkName: "components/cms-mediclub-block7" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubBlock8 = () => import('../../components/cms/mediclub/v2/CmsMediclubBlock8.vue' /* webpackChunkName: "components/cms-mediclub-block8" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Auth = () => import('../../components/cms/mediclub/v3/CmsMediclub3Auth.vue' /* webpackChunkName: "components/cms-mediclub3-auth" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3BenefitItem = () => import('../../components/cms/mediclub/v3/CmsMediclub3BenefitItem.vue' /* webpackChunkName: "components/cms-mediclub3-benefit-item" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3BenefitMenu = () => import('../../components/cms/mediclub/v3/CmsMediclub3BenefitMenu.vue' /* webpackChunkName: "components/cms-mediclub3-benefit-menu" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Block1 = () => import('../../components/cms/mediclub/v3/CmsMediclub3Block1.vue' /* webpackChunkName: "components/cms-mediclub3-block1" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Block10 = () => import('../../components/cms/mediclub/v3/CmsMediclub3Block10.vue' /* webpackChunkName: "components/cms-mediclub3-block10" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Block11 = () => import('../../components/cms/mediclub/v3/CmsMediclub3Block11.vue' /* webpackChunkName: "components/cms-mediclub3-block11" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Block1Footer = () => import('../../components/cms/mediclub/v3/CmsMediclub3Block1Footer.vue' /* webpackChunkName: "components/cms-mediclub3-block1-footer" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Block2 = () => import('../../components/cms/mediclub/v3/CmsMediclub3Block2.vue' /* webpackChunkName: "components/cms-mediclub3-block2" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Block3 = () => import('../../components/cms/mediclub/v3/CmsMediclub3Block3.vue' /* webpackChunkName: "components/cms-mediclub3-block3" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Block4 = () => import('../../components/cms/mediclub/v3/CmsMediclub3Block4.vue' /* webpackChunkName: "components/cms-mediclub3-block4" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Block5 = () => import('../../components/cms/mediclub/v3/CmsMediclub3Block5.vue' /* webpackChunkName: "components/cms-mediclub3-block5" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Block6 = () => import('../../components/cms/mediclub/v3/CmsMediclub3Block6.vue' /* webpackChunkName: "components/cms-mediclub3-block6" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Block7 = () => import('../../components/cms/mediclub/v3/CmsMediclub3Block7.vue' /* webpackChunkName: "components/cms-mediclub3-block7" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Block8 = () => import('../../components/cms/mediclub/v3/CmsMediclub3Block8.vue' /* webpackChunkName: "components/cms-mediclub3-block8" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Block9 = () => import('../../components/cms/mediclub/v3/CmsMediclub3Block9.vue' /* webpackChunkName: "components/cms-mediclub3-block9" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3BlockSurvey = () => import('../../components/cms/mediclub/v3/CmsMediclub3BlockSurvey.vue' /* webpackChunkName: "components/cms-mediclub3-block-survey" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Cards = () => import('../../components/cms/mediclub/v3/CmsMediclub3Cards.vue' /* webpackChunkName: "components/cms-mediclub3-cards" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Fallback = () => import('../../components/cms/mediclub/v3/CmsMediclub3Fallback.vue' /* webpackChunkName: "components/cms-mediclub3-fallback" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Heading = () => import('../../components/cms/mediclub/v3/CmsMediclub3Heading.vue' /* webpackChunkName: "components/cms-mediclub3-heading" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Menu = () => import('../../components/cms/mediclub/v3/CmsMediclub3Menu.vue' /* webpackChunkName: "components/cms-mediclub3-menu" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclub3Text = () => import('../../components/cms/mediclub/v3/CmsMediclub3Text.vue' /* webpackChunkName: "components/cms-mediclub3-text" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubCardsSport = () => import('../../components/cms/mediclub/v3/CmsMediclubCardsSport.vue' /* webpackChunkName: "components/cms-mediclub-cards-sport" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubSelfCare = () => import('../../components/cms/mediclub/v3/CmsMediclubSelfCare.vue' /* webpackChunkName: "components/cms-mediclub-self-care" */).then(c => wrapFunctional(c.default || c))
export const CmsMediclubSportHero = () => import('../../components/cms/mediclub/v3/CmsMediclubSportHero.vue' /* webpackChunkName: "components/cms-mediclub-sport-hero" */).then(c => wrapFunctional(c.default || c))
export const CustomerOrderItem = () => import('../../components/customer/orders/item/CustomerOrderItem.vue' /* webpackChunkName: "components/customer-order-item" */).then(c => wrapFunctional(c.default || c))
export const CustomerOrderItemBeneficiary = () => import('../../components/customer/orders/item/Beneficiary/CustomerOrderItemBeneficiary.vue' /* webpackChunkName: "components/customer-order-item-beneficiary" */).then(c => wrapFunctional(c.default || c))
export const CustomerOrderItemDetail = () => import('../../components/customer/orders/item/detail/CustomerOrderItemDetail.vue' /* webpackChunkName: "components/customer-order-item-detail" */).then(c => wrapFunctional(c.default || c))
export const CustomerOrderItemAppointment = () => import('../../components/customer/orders/item/type/CustomerOrderItemAppointment.vue' /* webpackChunkName: "components/customer-order-item-appointment" */).then(c => wrapFunctional(c.default || c))
export const CustomerOrderItemMedicalPackage = () => import('../../components/customer/orders/item/type/CustomerOrderItemMedicalPackage.vue' /* webpackChunkName: "components/customer-order-item-medical-package" */).then(c => wrapFunctional(c.default || c))
export const CustomerOrderItemPrepaidCard = () => import('../../components/customer/orders/item/type/CustomerOrderItemPrepaidCard.vue' /* webpackChunkName: "components/customer-order-item-prepaid-card" */).then(c => wrapFunctional(c.default || c))
export const CustomerOrderItemSimpleWithLocation = () => import('../../components/customer/orders/item/type/CustomerOrderItemSimpleWithLocation.vue' /* webpackChunkName: "components/customer-order-item-simple-with-location" */).then(c => wrapFunctional(c.default || c))
export const CustomerOrderItemTypeLenses = () => import('../../components/customer/orders/item/type/CustomerOrderItemTypeLenses.vue' /* webpackChunkName: "components/customer-order-item-type-lenses" */).then(c => wrapFunctional(c.default || c))
export const CustomerOrderItemTypeSimple = () => import('../../components/customer/orders/item/type/CustomerOrderItemTypeSimple.vue' /* webpackChunkName: "components/customer-order-item-type-simple" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
